<template>
  <v-slider v-model="value"
            :disabled="!device.gatewayOnline"
            class="non-flip pt-10 pb-1 px-1"
            hide-details
            :min="actuator?.minValue"
            :max="actuator?.maxValue"
            thumb-label="always"
            :append-icon="increaseIcon"
            :prepend-icon="decreaseIcon"
            @click:append="increase"
            @click:prepend="decrease"
            @change="updateProp"
            :step="stepAmount"
            :ticks="showTicks"
            :tick-labels="tickLabels">
    <template v-slot:thumb-label="{ value }">
      <span class="font-weight-bold">{{ value }}</span>
      <sup>{{ actuator?.unit }}</sup>
    </template>
  </v-slider>
</template>

<script>

import deviceProperties from "@/config/deviceProperties.json";

export default {
  name: 'SliderInput',

  components: {},

  props: {
    device: Object,
    actuator: Object,
    dense: Boolean
  },

  data: function () {
    return {
      value: this.device.value,
      allowValueChange: true,
      cooldown: 60000,
      changeTimeout: null,
      loading: false
    }
  },

  computed: {
    increaseIcon() {
      return deviceProperties[this.actuator?.name]?.increaseIcon
    },

    decreaseIcon() {
      return deviceProperties[this.actuator?.name]?.decreaseIcon
    },
    stepAmount() {
      if (!this.dense) {
        return 1
      }
      return deviceProperties[this.actuator?.name]?.stepAmountIfDense || 1
    },
    showTicks() {
      if (!this.dense) {
        return false
      }
      return deviceProperties[this.actuator?.name]?.showTicksIfDense || false
    },
    tickLabels() {
      let tickLabels = []
      if (!this.showTicks) {
        return tickLabels
      }
      let min = parseInt(this.actuator?.minValue)
      let max = parseInt(this.actuator?.maxValue)
      for (let i = min; i <= max; i += this.stepAmount) {
        tickLabels.push(i + this.actuator?.unit)
      }
      return tickLabels
    }
  },

  methods: {
    increase() {
      this.value+= this.stepAmount
      this.updateProp()
    },

    decrease() {
      this.value-= this.stepAmount
      this.updateProp()
    },

    directGet() {
      this.$rhRequest.sendGet({
            endpoint: `device/default-actor-dco/${this.device.encryptedId}`,
          },
          (r) => {
            if (this.value != r?.data?.data) {
              this.value = r?.data?.data?.mapping?.value
              // eslint-disable-next-line vue/no-mutating-props
              this.device.lastChanged = Math.floor(Date.now() / 1000)
            }
          }
      )
    },

    updateProp() {
      this.loading = true
      this.allowValueChange = false
      if (this.changeTimeout) {
        clearTimeout(this.changeTimeout)
      }
      this.changeTimeout = setTimeout(() => {
        this.allowValueChange = true
        this.directGet()
      }, this.cooldown)
      this.$rhRequest.sendPost({
        endpoint: 'devices/update-property',
        data: {
          deviceId: this.device.id,
          value: this.value
        }
      }, () => {
        this.loading = false
      }, (error) => {
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        console.error(error)
        this.loading = false
      })
    }
  },

  watch: {
    device: function () {
      if (this.allowValueChange) {
        this.value = this.device?.value
      }
    }
  }
}

</script>
