<template>
  <dialog-frame :title="$t('profile-confirm-delete-dialog.title').toString()"
                icon="delete_forever"
                closable
                @close="abort"
                color="error">

    <template v-slot:content>
      <div v-if="!showPasswordTextfield">
        {{ $t('profile-confirm-delete-dialog.text') }}
      </div>
      <div v-else>
        <div>
          {{ $t('profile-confirm-delete-dialog.text.password') }}
        </div>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field :type="passwordVisible ? 'text' : 'password'"
                        v-model="confirmedPassword"
                        class="mt-4"
                        hide-details="auto"
                        outlined
                        :rules="passwordRule">
            <template v-slot:append>
              <v-icon v-if="passwordVisible" @click="passwordVisible = !passwordVisible">visibility</v-icon>
              <v-icon v-else @click="passwordVisible = !passwordVisible">visibility_off</v-icon>
            </template>
          </v-text-field>
        </v-form>
      </div>
    </template>

    <template v-slot:actions>
      <v-btn plain block color="error"
             class="font-weight-bold d-block"
             @click="showPasswordTextfield ? deleteConfirm() : showPasswordTextfield = true">
        {{ showPasswordTextfield ? $t('app.delete-account') : $t('app.continue')}}
      </v-btn>
    </template>
  </dialog-frame>
</template>

<script>

import DialogFrame from "@/templates/dialogs/DialogFrame";

export default {
  name: 'ProfileConfirmDeleteDialog',

  components: {
    DialogFrame
  },
  data() {
    return {
      showPasswordTextfield: false,
      confirmedPassword: '',
      valid: false,
      passwordVisible: false
    }
  },
  methods: {
    abort() {
     this.resetAndCloseDialog()
    },
    deleteConfirm () {
      if(this.$refs.form.validate()) {
        this.$rhRequest.sendPost({
          endpoint: "user/expire-user",
          data: {password: (this.confirmedPassword)}
        }, (response) => {
          if (response?.data?.data?.correctPassword) {
            this.resetAndCloseDialog()
            // catch to prevent "Redirected when going from "/profile" to "/logout"
            // via a navigation guard" error from vue-router
            this.$router.push('logout').catch(() => {})
            this.$root.bisatoast.success({message: this.$t('app.delete-account.success')})
          } else {
            this.resetAndCloseDialog()
            this.$root.bisatoast.error({message: this.$t('app.delete-account.wrong-password')})
          }
        }, () => {
          this.resetAndCloseDialog()
          this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        })
      }
    },
    resetAndCloseDialog() {
      this.confirmedPassword = ''
      this.showPasswordTextfield = false
      this.$root.bisadialog.toggle('profileConfirmDelete', false)
    }
  },
  computed: {
    passwordRule() {
      return [
        v => !!v || this.$t('app.rules.required'),
      ]
    }
  }

};
</script>

